import { PATHS } from "@/internals/constants/paths";

export const paths = {
  home() {
    return PATHS.HOME;
  },
  homeB() {
    return PATHS.HOME_B;
  },
  termsAndConditions() {
    return PATHS.TERMS_AND_CONDITIONS;
  },
  termsOfUse() {
    return PATHS.TERMS_OF_USE;
  },
  privacyPolicy() {
    return PATHS.PRIVACY_POLICY;
  },
  cookiesPolicy() {
    return PATHS.COOKIES_POLICY;
  },
  contactUs() {
    return PATHS.CONTACT_US;
  },
  faq() {
    return PATHS.FAQ;
  },
  inactive() {
    return PATHS.INACTIVE;
  },
  pricing() {
    return PATHS.PRICING;
  },
  reset() {
    return PATHS.RESET;
  },
  whoWeAre() {
    return PATHS.WHOWEARE;
  },
  qrEditor(qrIdSlug?: string, isDesignOrRegister?: "design" | "register") {
    const basePath = `${PATHS.QR_EDITOR}`;
    const idSegment = qrIdSlug ? `/${qrIdSlug}` : "";
    const designSegment =
      isDesignOrRegister === "design" && qrIdSlug ? "/design" : "";
    const registerSegment =
      isDesignOrRegister === "register" && qrIdSlug ? "/register" : "";

    return `${basePath}${idSegment}${designSegment}${registerSegment}`;
  },
  notFound() {
    return PATHS.NOT_FOUND;
  },
  myAccount() {
    return PATHS.MY_ACCOUNT;
  },
  myBilling() {
    return PATHS.MY_BILLING;
  },
  myQrCodes(qrIdSlug?: string) {
    return qrIdSlug
      ? `${PATHS.MY_QR_CODES_DETAILS}/${qrIdSlug}`
      : PATHS.MY_QR_CODES;
  },
  myQrAnalytics() {
    return PATHS.MY_QR_ANALYTICS;
  },
  payment() {
    return PATHS.PAYMENT;
  },
  verifyPayment() {
    return PATHS.VERIFY_PAYMENT;
  },
  thankYou() {
    return PATHS.THANK_YOU;
  },
  unsubscribe() {
    return PATHS.UNSUBSCRIBE;
  },
  unsubscribeReasons() {
    return PATHS.UNSUBSCRIBE_REASONS;
  },
  unsubscribeConfirmation() {
    return PATHS.UNSUBSCRIBE_CONFIRMATION;
  }
};
